// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_inner {
    & {
        padding-bottom: remify($content-padding * 1.666666666666667);
        padding-top: remify($content-padding * 1.666666666666667);
    }
}

.hero_title {
    & {
        font-size: remify(55, 16);
    }
}

// fullbleed variant

.hero-block.-fullbleed {
    & {
        padding-bottom: remify($content-padding * 2);
        padding-top: remify($content-padding * 1.666666666666667);
    }

    > .hero_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
