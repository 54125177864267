// Styles written by Jordan Bearce @ Weblinx, Inc.

.gallery {
    width: 100%;
}
.gallery .gallery-item {
    display: block;
    margin: 0 auto;
    padding: remify(12, 16);
    width: 100%;
}

.gallery-item a,
.gallery-item img {
    margin: 0 auto;
    padding: 0;
}
