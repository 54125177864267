// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Link
\* ------------------------------------------------------------------------ */

.link {
    & {
        color: $primary;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        opacity: 0.5;
    }

    &.-light {
        color: $light;
    }

    &.-light:hover {
        color: $accent;
    }
}
