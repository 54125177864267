// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_inner {
    & {
        // CSS lock, see https://blog.typekit.com/2016/08/17/flexible-typography-with-css-locks/
        padding-bottom: calc(#{(25em / 16)} + (#{(50 / 16)} - #{(25 / 16)}) * ((100vw - #{(640em / 16)}) / (#{(1024 / 16)} - #{(640 / 16)})));
        // CSS lock, see https://blog.typekit.com/2016/08/17/flexible-typography-with-css-locks/
        padding-top: calc(#{(25em / 16)} + (#{(50 / 16)} - #{(25 / 16)}) * ((100vw - #{(640em / 16)}) / (#{(1024 / 16)} - #{(640 / 16)})));
    }
}

.hero_title {
    & {
        // CSS lock, see https://blog.typekit.com/2016/08/17/flexible-typography-with-css-locks/
        font-size: calc(#{(27.5em / 16)} + (#{(55 / 16)} - #{(27.5 / 16)}) * ((100vw - #{(640em / 16)}) / (#{(1024 / 16)} - #{(640 / 16)})));
    }
}

// fullbleed variant

.hero-block.-fullbleed {
    & {
        // CSS lock, see https://blog.typekit.com/2016/08/17/flexible-typography-with-css-locks/
        padding-bottom: calc(#{(25em / 16)} + (#{(50 / 16)} - #{(25 / 16)}) * ((100vw - #{(640em / 16)}) / (#{(1024 / 16)} - #{(640 / 16)})));
        // CSS lock, see https://blog.typekit.com/2016/08/17/flexible-typography-with-css-locks/
        padding-top: calc(#{(25em / 16)} + (#{(50 / 16)} - #{(25 / 16)}) * ((100vw - #{(640em / 16)}) / (#{(1024 / 16)} - #{(640 / 16)})));
    }

    > .hero_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
