// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Custom

.swiper-container {
    border: remify(11, 16) solid $dark_alt;
}

.swiper-image {
    .swiper-container.-fixedheight & {
        height: remify(420, 16);
    }
}

.swiper-title {
    & {
        font-size: remify(79.97, 16);
    }
}

.swiper-button-prev,
.swiper-button-next {
    & {
        background-color: transparent;
        opacity: 1;
    }

    .icon {
        color: $primary;
    }
}
