// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */

@import "../module/widget/_widget_xl";

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
