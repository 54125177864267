// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $dark_alt;
        display: block;
        font-family: $body-font;
        font-size: remify(15, 16);
        font-weight: 400;
        line-height: remify(20, 15);
        margin: remify(0 0 25.218846153846154, 15);
    }
}

// Tablet variant

@media screen and (min-width: $xs-break) {
    .text {
        & {
            font-size: remify(16, 16);
        }
    }
}

// label variant

.text.-label {
    & {
        cursor: pointer;
    }
}
