// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }

    .is-fluidboxactive & {
        position: static;
    }
}

.content_inner {
    & {
        @extend %clearfix;
        background: $background;
        margin: 0 auto;
        width: remify($content-width);
        max-width: 100%;
    }

    &._site-width {
        width: remify($site-width);
    }
}

.content_post {
    & {
        padding: remify($column-gap * 2 $column-gap, 16);
    }

    // not iddeal, but since it's a shortcode I don't have much choice...

    .interactive-graphic {
        margin-left: remify(-$content-padding);
        margin-right: remify(-$content-padding);
    }
}

.content_sidebar_inner {
    & {
        background: lighten(desaturate(adjust-hue($primary, 29.2223), 9.9965), 58.2353);
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    .nf-form-cont {
        background: $background;
        padding: remify(10);
    }

    .nf-form-cont .nf-form-fields-required {
        left: -999em;
        position: absolute;
        top: -999em;
    }

    .nf-form-cont .input {
        font-size: remify(16, 16);
        padding-left: remify(4, 16);
        padding-right: remify(4, 16);
    }

    .nf-form-cont .input:not(.-checkbox):not(.-radio) {
        border-left: 0;
        border-right: 0;
        border-top: 0;
        margin: 0;
    }

    .nf-form-cont .button {
        display: block;
        font-size: remify(16, 16);
        margin-bottom: 0;
        width: 100%;
    }

    .nf-form-cont .nf-error-msg {
        margin-top: 0;
    }
}

// fullbleed variant

.content-block.-fullbleed {
    & {
        background: $background;
    }

    > .content_inner {
        background: none;
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}

// alt variant

.content-block.-alt {
    & {
        background-color: $background_alt;
    }

    .content_inner {
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    & + & > .content_inner {
        border-top: remify(5) solid darken(saturate(adjust-hue($primary, 0.6155), 0.6821), 12.9412);
    }

    .content_post {
        padding: 0;
    }
}

// alt fullbleed variant

.content-block.-alt.-fullbleed {
    & {
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    & + & {
        border-top: remify(5) solid darken(saturate(adjust-hue($primary, 0.6155), 0.6821), 12.9412);
    }

    > .content_inner {
        padding: 0;
    }

    & + & > .content_inner {
        border-top-width: 0;
    }
}

// trim variant

.content-block.-trim {
    .content_post {
        padding-bottom: remify(5);
        padding-top: remify(5);
    }
}

.content-block.-bordered {
    & {
        border-top: remify(9) solid $primary;
        padding-top: remify(57);
        padding-bottom: remify(33);
    }
}

.content-block_callout {
    padding: remify($content-padding 33);
}
