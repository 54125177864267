// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block.-absolute {
    & {
        background: none;
        left: 50%;
        position: absolute;
        top: 23%;
        transform: translate(-50%, 0);
        width: 100%;
    }

    .content_inner {
        background: $light;
    }
}
