// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_hero-wrapper {
    & {
        padding: 0;
    }

    .placeholder {
        display: block !important;
        opacity: 0;
        visibility: hidden;
    }
}

.header-block {
    @media screen and (max-width: remify(($site-width + 100), 16)) {
        font-size: (16 / ($site-width + 100)) * 100vw;
    }
}

.header_inner {
    & {
        padding-bottom: remify($content-padding);
        padding-top: remify(($content-padding * 0.666666666666667));
    }
}

.header_logo {
    & {
        margin: 0;
        width: remify(304, 16);
    }
}

.header_row {
    flex-wrap: nowrap;
    align-items: center;
}

// fullbleed varaint

.header-block.-fullbleed {
    & {
        margin: remify($content-padding 0 0, 16);
    }

    > .header_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}

.header-top {
    & {
        border-bottom: remify(3, 16) solid $primary;
        padding-bottom: remify(18, 16);
    }

    .header_text {
        padding: remify(0 0 5, 16);
    }
}

.header-button {
    & {
        margin: 0;
        padding: 0;
    }

    .button_header {
        display: inline-block;
    }
}
