// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

html,
body {
    & {
        background: $light;
        height: 100%;
        width: 100%;
    }
}

.page_container {
    & {
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        min-height: 100%;
        overflow-x: hidden;
        position: relative;
    }
}
