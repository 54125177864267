// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Custom

.swiper-image {
    .swiper-container.-fixedheight & {
        height: remify(370, 16);
    }
}

.swiper-title {
    & {
        // CSS lock, see https://blog.typekit.com/2016/08/17/flexible-typography-with-css-locks/
        font-size: calc(#{(39.95em / 16)} + (#{(79.97 / 16)} - #{(39.95 / 16)}) * ((100vw - #{(640em / 16)}) / (#{(1024 / 16)} - #{(640 / 16)})));
    }
}
