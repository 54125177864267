// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Interactive Graphic
\* ------------------------------------------------------------------------ */

.interactive-graphic_figure {
    & {
        position: relative;
    }

    @media screen and (max-width: remify($site-width, 16)) {
        font-size: (16 / 1200) * 100vw;
    }
}

.interactive-graphic_image {
    & {
        display: block;
        width: 100%;
    }
}

.interactive-graphic_menu {
    & {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.interactive-graphic_menu_item {
    & {
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
    }
}

.interactive-graphic_menu_button {
    & {
        background: $primary;
        border: remify(6, 16) solid $background;
        border-radius: 50%;
        box-shadow: remify(0 0 3.78 1.52, 16) transparentize($dark, 0.65);
        cursor: pointer;
        display: block;
        height: remify(45, 16);
        margin: 0;
        padding: 0;
        transition: background 0.15s;
        width: remify(45, 16);
    }

    &:focus,
    &:hover {
        background: $primary_alt;
    }
}

.interactive-graphic_modal {
    & {
        background: $background;
        border-radius: remify(28, 16);
        box-shadow: remify(0 0 20.59 8.41, 16) transparentize($dark, 0.9);
        display: none;
        left: 0;
        margin: auto;
        max-height: 75%;
        max-width: remify($site-width - (($content-padding * 2.666666666666667) * 2));
        position: fixed;
        right: 0;
        top: remify(100, 16);
        width: 90%;
        z-index: 9999;
    }

    @supports (transform: translateY(-50%)) {
        top: 50%;
        transform: translateY(-50%);
    }

    &.is-active {
        display: block;
        display: flex;
    }
}

.interactive-graphic_modal_inner {
    & {
        flex: 1 1 auto;
        overflow: auto;
        padding: remify(40);
        width: 100%;
    }
}

.interactive-graphic_modal_row {
    &.-padded {
        width: calc(100% + #{remify(40)});
    }

    &.-padded > .col,
    &.-padded > [class^="col-"] {
        padding-right: remify(40);
    }
}

.interactive-graphic_modal_image {
    & {
        display: block;
        width: 100%;
    }
}

.interactive-graphic_modal_closer {
    & {
        background: $primary;
        border: remify(10, 16) solid $background;
        border-radius: 50%;
        box-shadow: remify(0 0 5.68 2.32, 16) transparentize($dark, 0.65);
        color: $light;
        cursor: pointer;
        display: block;
        height: remify(70, 16);
        margin: 0;
        padding: 0;
        position: absolute;;
        right: 0;
        top: 0;
        transform: translateX(37.5%) translateY(-37.5%);
        transition: background 0.15s;
        width: remify(70, 16);
    }

    &:focus,
    &:hover {
        background: $primary_alt;
    }
}
