// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_hero-wrapper {
    & {
        background-image: url("../media/top-background.png");
        background-position: top center;
        background-size: cover;
    }

    &.inner-page {
        padding: remify(0 0 50, 16);
    }
}

.header-block {
    & {
        @include layer("header");
    }

    .is-fluidboxactive & {
        z-index: -1;
    }
}

.header_inner {
    & {
        margin: 0 auto;
        max-width: remify($site-width + ($content-padding * 2));
        padding: remify(0 $content-padding);
    }
}

.header_link {
    & {
        color: $accent;
    }

    &:focus,
    &:hover {
        color: $accent_alt;
    }
}

.header_title {
    & {
        color: $primary;
        font-size: remify(17, 16);
        text-transform: uppercase;
    }
}

.header_logo {
    & {
        width: remify(200, 16);
    }
}

.header_menu-list_container {
    .menu-list.-navigation {
        display: flex;
        justify-content: space-between;
        margin-top: remify(4, 16);
        transform: translateX(#{remify(-11, 16)});
        width: calc(100% + #{remify(24, 16)});
    }

    .menu-list.-social {
        font-size: remify(25, 16);
        margin: remify(0 20 0 0, 25);
    }

    .menu-list.-social .menu-list_link {
        color: $accent;
    }

    .menu-list.-social .menu-list_link:focus,
    .menu-list.-social .menu-list_link:hover {
        opacity: 0.8;
    }
}

// fullbleed varaint

.header-block.-fullbleed {
    & {
        background: rgba(0, 0, 0, 0.34);
        margin: 0;
        padding: remify(25 0, 16);
    }

    > .header_inner {
        background: none;
        border-bottom: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}

.header-top {
    .header_text {
        color: $light;
        font-family: $nav-font;
        font-size: remify(16, 16);
        padding: remify(7 0, 16);
    }

    .header-message {
        padding-right: remify(20, 16);
    }

    .special-font {
        color: $primary;
        font-family: $special-font;
        font-size: remify(38, 16);
        padding: remify(0 4 0 10, 16);
    }

    .special-font:first-child {
        padding-left: 0;
    }
}

.header-button {
    & {
        margin: remify(0 20 20, 16);
    }

    .button_header {
        background: rgba(221, 255, 118, 0.3);
        border-radius: remify(14, 16);
        color: $accent;
        display: block;
        font-family: $nav-font;
        font-size: remify(22, 16);
        font-weight: 400;
        letter-spacing: remify(0.5, 16);
        margin: 0;
        padding: remify(10 15, 16);
        text-transform: uppercase;
    }

    &:focus,
    &:hover {
        background: $secondary_alt;
    }
} 
