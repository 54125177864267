// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * CTA
\* ------------------------------------------------------------------------ */

.cta {
    & {
        border-radius: remify(45, 16);
        padding: remify(26 80 18, 16);
    }
}

.cta_text {
    & {
        font-size: remify(17, 16);
    }
}
