// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    & {
        @extend %clearfix;
    }

    // links

    a:not(.button) {
        color: $primary;
        font-weight: 700;
        text-decoration: none;
        transition: color 0.15s;
    }

    a:not(.button):hover {
        color: $primary_alt;
    }

    // titles

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $secondary;
        font-family: $heading-font;
    }

    h1 {
        color: $primary;
    }

    h1,
    h2 {
        font-family: $body-font;
        font-size: remify(20, 16);
        font-weight: 900;
        line-height: remify(27, 20);
        margin: remify(0 0 10, 20);
    }

    h3 {
        font-size: remify(17, 16);
        font-weight: 700;
        line-height: remify(25, 17);
        margin: 0;
    }

    h4 {
        font-family: $special-font;
        font-size: remify(30, 16);
        font-weight: 400;
        line-height: remify(35.4, 30);
        margin: 0;
    }

    h5,
    h6 {
        font-size: remify(16.666666666666667, 16);
        font-weight: 700;
        line-height: remify(23.111111111111111, 16.666666666666667);
        margin: remify(0 0 11.666666666666667, 16.666666666666667);
    }

    // text

    p,
    ol,
    ul,
    table {
        color: $dark_alt;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 400;
        line-height: remify(27, 16);
        margin: remify(0 0 27, 16);
    }

    ol {
        padding-left: remify(18, 13.405);
    }

    ul {
        color: darken($dark, 22.3529);
        font-size: remify(16.75625, 16);
        list-style: none;
        padding-left: 0;
    }

    ul li {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ED7523' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z' /%3E%3C/svg%3E%0A");
        background-position: left remify(2.5, 16.75625);
        background-repeat: no-repeat;
        background-size: remify(15.080625, 16.75625);
        padding-left: remify(25.134375, 16.75625);
    }

    ol li:not(:last-child),
    ul li:not(:last-child) {
        margin-bottom: remify(16, 16);
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
        font-size: 1em;
    }

    .table_container {
        overflow: auto;
        width: 100%;
    }

    table {
        font-size: remify(14, 16);
        border-collapse: collapse;
        width: 100%;
    }

    table td,
    table th {
        padding: remify(6 8, 13.405);
        text-align: left;
        vertical-align: top;
    }

    table tbody th,
    table > tr > th,
    table[data-stacked="true"] tbody td:before {
        font-weight: 700;
        text-transform: uppercase;
    }

    table thead td,
    table thead th {
        background: $accent;
        border-color: $accent;
        color: $dark;
        font-weight: 700;
        padding: remify(6 8, 16);
    }

    table > tr:nth-child(even) td,
    table > tr:nth-child(even) th,
    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
        background: lighten($accent, 21%);
    }

    // scrolling responisve tables

    @media screen and (max-width: $xs-break - (1 / 16)) {
        table {
            overflow: auto;
            padding-bottom: 1px;
        }
    }

    // blockquote

    blockquote {
        font-style: italic;
        margin: remify(0 0 18, 16);
        padding: remify(0 0 0 28, 16);
        position: relative;
    }

    blockquote p,
    blockquote ol,
    blockquote ul,
    blockquote table {
        color: lighten($dark, 50);
        font-size: remify(14, 16);
    }

    blockquote > :last-child {
        margin-bottom: 0;
    }

    // horizontal rule

    hr {
        background: darken($background_alt, 10);
        border: 0;
        clear: both;
        height: remify(1);
        margin: remify(0 0 20, 16);
        width: 100%;
    }

    // images

    img {
        display: block;
        height: auto !important;
        max-width: 100%;
    }

    .aligncenter,
    .alignleft,
    .alignright {
        float: none;
        margin-bottom: remify(32, 16);
        margin-left: auto;
        margin-right: auto;
    }
}

// Tablet variant

@media screen and (min-width: $xs-break) {
    .user-content {
        h1 {
            font-size: remify(27, 16);
        }

        h2 {
            font-size: remify(26, 16);
        }

        h3 {
            font-size: remify(26, 16);
        }

        h4 {
            font-size: remify(40, 16);
        }

        h5,
        h6 {
            font-size: remify(20, 16);
        }

        p,
        ol,
        ul,
        table {
            font-size: remify(20, 16);
            line-height: remify(33, 20);
        }

        ol.columns,
        ul.columns {
            columns: 3;
        }

        ol.columns li,
        ul.columns li {
            break-inside: avoid;
        }

        ul {
            font-size: remify(20, 16);
        }

        blockquote {
            margin-bottom: remify(24, 16);
            padding-left: remify(36, 16);
        }

        blockquote:before {
            font-size: remify(24, 16);
        }

        blockquote p,
        blockquote ol,
        blockquote ul,
        blockquote table {
            font-size: remify(18, 16);
        }

        .alignleft {
            float: left;
            margin-right: remify(25, 16);
            max-width: (100% / 3);
        }

        .alignright {
            float: right;
            margin-left: remify(25, 16);
            max-width: (100% / 3);
        }
    }
}

// light variant

.user-content.-light {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    table,
    ul {
        color: $light;
    }
}
