// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

// excerpt variant

.article.-excerpt {
    &:not(:last-of-type){
        border-bottom: remify(1) solid darken($background_alt, 10);
        margin-bottom: remify(20, 16);
    }

    .article_title {
        font-size: remify(18, 16);
    }

    .article_header > .article_title:not(:last-child) {
        margin-bottom: 0;
    }

    .article_header > .article_title:not(:last-child) + * {
        margin-bottom: remify(10, 16);
    }
}
