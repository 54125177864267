// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-container {
    border: remify(8, 16) solid $dark_alt;
    max-width: remify($content-width, 16);
}

.swiper-slide {
    & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        margin: 0;
        overflow: hidden;
    }
}

.swiper-picture,
.swiper-picture {
    & {
        flex: none;
    }
}

.swiper-image {
    & {
        display: block;
        width: 100%;
    }

    .swiper-container.-fixedheight & {
        height: remify(320, 16);
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        width: auto;
    }
}

.swiper-caption {
    & {
        left: 0;
        padding: remify(($content-padding * 0.5) $content-padding);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    .swiper-container.-fullbleed & .swiper-caption-inner {
        margin: 0 auto;
        max-width: remify($site-width);
    }
}

.swiper-title {
    & {
        color: mix($primary, darken(saturate(adjust-hue($primary, 0.9870), 0.5495), 25.4902));
        font-size: remify(39.985, 16);
        text-shadow: remify(0 3 7, 39.985) transparentize($dark, 0.65);
        text-transform: uppercase;
    }

    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background: linear-gradient(to bottom, $primary 25%, darken(saturate(adjust-hue($primary, 0.9870), 0.5495), 25.4902) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 99% 95%;
        color:transparent;
    }
}

.swiper-button-prev,
.swiper-button-next {
    & {
        border: 0;
        color: $primary;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        transition: opacity 0.15s;
        top: 0;
        width: remify(55, 16);
    }

    .icon {
        font-size: remify(48, 16);
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    & {
        left: 0;
    }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    & {
        right: 0;
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        bottom: auto;
        top: 0;
    }
}

.swiper-pagination-bullet {
    & {
        background: transparentize($dark, 0.5);
        height: remify(10, 16);
        opacity: 1;
        transition: background 0.15s;
        width: remify(10, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(20 5, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background: $primary;
    }
}
