// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

// callout variant

.widget.-callout {
    .widget_title {
        font-size: remify(55, 16);
    }
}

.widget.-callout.-inner {
    .widget_bigtitle {
        font-size: remify(68, 16);
    }

    .widget_subtitle {
        font-size: remify(20, 16);
    }
}
