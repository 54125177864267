// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(23.005, 16);
        font-weight: 800;
        letter-spacing: remify(tracking(13, 23.005), 23.005);
        line-height: remify(28.75625, 23.005);
        margin: remify(0 0 10, 23.005);
    }
}

// Tablet variant

@media screen and (min-width: $xs-break) {
    .title {
        & {
            font-size: remify(30.68, 16);
        }
    }
}
