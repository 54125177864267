// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #ED7523;
$primary_alt: #E3B595;

$secondary: #08162A;
$secondary_alt: #08162A;

$accent: #DDFF76;
$accent_alt: #CEDD42;

$light: #FFFFFF;
$light_alt: #EEEEEE;

$dark: #000000;
$dark_alt: #2A2A2A;

$foreground: #9F9F9F;
$foreground_alt: #777777;

$background: #FFFFFF;
$background_alt: #F2F2F2;

$page_background: #FFFFFF;
$page_background_alt: #D0D0D0;

$warning: #A9423B;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$nav-font: "Prohibition", sans-serif;
$heading-font: "Bitter", serif;
$body-font: "Bitter", serif;
$special-font: "Creepster", cursive;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1362;
$content-width: 1168;
$column-gap: 25;
$content-padding: 30;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 5,
    navigation: 4,
    hero: 3,
    content: 2,
    footer: 1,
);
