// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

// callout variant

.widget.-callout {
    & {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 0;
        margin: remify(0 0 33);
        padding-bottom: (329 / 435) * 100%;
        position: relative;
        width: 100%;
    }

    .widget_link {
        & {
            bottom: 0;
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .widget_title {
        color: $accent;
        font-family: $special-font;
        font-size: remify(32, 16);
        font-weight: 400;
        left: 0;
        line-height: remify(16, 16);
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        @supports (transform: translateX(-50%) translateY(-50%)) {
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

.widget.-callout.-inner {
    & {
        background: $accent;
        height: 0;
        margin: remify(0 0 33);
        padding-bottom: (158 / 360) * 100%;
        position: relative;
        width: 100%;
    }

    .widget_titles {
        left: 0;
        padding-top: remify(12, 16);
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        @supports (transform: translateX(-50%) translateY(-50%)) {
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .widget_bigtitle {
        color: $primary;
        font-size: remify(40, 16);
        font-family: $special-font;
        font-weight: 400;
        left: 0;
        line-height: 1;
        margin: 0;
        text-align: center;
        top: 0;
        width: 100%;
    }

    .widget_subtitle {
        color: $dark;
        font-family: $body-font;
        font-size: remify(12, 16);
        font-weight: 700;
        line-height: 1;
        margin: 0;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
    }
}
