// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Icon
\* ------------------------------------------------------------------------ */

.icon {
    & {
        align-self: center;
        display: inline-flex;
        height: remify(14, 16);
        position: relative;
        width: remify(14, 16);
    }
}

.icon_svg {
    & {
        bottom: 0;
        height: remify(14, 16);
        position: absolute;
        width:  remify(14, 16);
    }
}
