// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Modal
\* ------------------------------------------------------------------------ */

.modal {
    & {
        background: $background;
        border: remify(1) solid darken($background_alt, 10);
        bottom: 0;
        display: block;
        height: remify(400, 16);
        left: 0;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        opacity: 0;
        overflow: auto;
        padding: remify(20, 16);
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity 0.15s, transform 0s 0.15s;
        transform: translateX(-100%);
        visibility: hidden;
        width: remify(750, 16);
        z-index: 999;
    }

    &.is-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.15s;
        visibility: visible;
    }

    @media screen and (min-width: 601px) {
        .admin-bar & {
            top: 46px;
        }
    }

    @media screen and (min-width: 783px) {
        .admin-bar & {
            top: 32px;
        }
    }
}

.modal_menu-toggle {
    position: absolute;
    right: remify(12, 16);
    top: remify(12, 16);
}
