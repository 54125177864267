// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $primary;
        border: 0;
        border-radius: remify(15, 16);
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-family: $heading-font;
        font-size: remify(16, 16);
        font-weight: 700;
        line-height: remify(20, 20);
        outline: 0;
        margin: remify(0 0 20, 20);
        padding: remify(20 35, 20);
        text-decoration: none;
        transition: background 0.15s;
    }

    &:focus,
    &:hover {
        background: $primary_alt;
    }
}

.button_alt {
    & {
        background: $secondary;
        border: 0;
        color: $accent;
    }

    &:focus,
    &:hover {
        background: $secondary;
        opacity: 0.5;
    }
}

// Desktop variant

@media screen and (min-width: $xs-break) {
    .button {
        font-size: remify(20, 16);
    }
}
