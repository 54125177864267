// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget.-callout {
    .widget_title {
        font-size: remify(32, 16);
    }
}

.widget.-callout.-inner {
    .widget_bigtitle {
        font-size: remify(40, 16);
    }

    .widget_subtitle {
        font-size: remify(12, 16);
    }
}
